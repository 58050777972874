<template>
  <login-page-template>
    <sign-in-form />
  </login-page-template>
</template>

<script>
import LoginPageTemplate from '@/components/login-page/LoginPageTemplate.vue';
import SignInForm from '@/components/login-page/SignInForm.vue';

export default {
  name: 'SignInPage',
  components: { LoginPageTemplate, SignInForm }
};
</script>
