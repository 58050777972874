<template>
  <section class="section">
    <h2 class="section__title">Sign in</h2>
    <v-form ref="form" class="login-form">
      <v-row>
        <v-text-field
          v-model="username"
          background-color="white"
          label="Administrator Login"
          placeholder=" "
          outlined
          :rules="loginRules"
        />
      </v-row>
      <v-row>
        <v-text-field
          v-model="password"
          background-color="white"
          label="Password"
          outlined
          placeholder=" "
          :type="passwordFieldType"
          :append-icon="passwordFieldIcon"
          :rules="passwordRules"
          @click:append="changePasswordVisibility"
        />
      </v-row>
      <v-row v-if="hasFormError" class="mb-3 red--text text--accent-2">
        {{ formErrorMessage }}
      </v-row>
      <v-row>
        <v-btn
          tile
          large
          color="light-blue darken-4 mt-2"
          class="white--text"
          :loading="isLoading"
          @click="onSignIn"
        >
          Sign In
        </v-btn>
      </v-row>
    </v-form>
  </section>
</template>

<script>
import { mapActions } from 'vuex';

import types from '@/store/types';
import { NETWORK_ERROR } from '@/constants/errors';

const LOGIN_RULES = [v => !!v || 'Login is required'];

const PASSWORD_RULES = [
  v => !!v || 'Password is required',
  v => (v || '').length > 7 || 'Must contain at least 8 characters'
];

const FORM_ERRORS_MESSAGES = {
  CREDENTIALS_ERROR: 'Incorrect login or password',
  NETWORK_ERROR: 'Internet disconnected'
};

export default {
  name: 'SignInForm',
  data() {
    return {
      isPasswordVisible: false,
      loginRules: [],
      passwordRules: [],
      username: '',
      password: '',
      isLoading: false,
      hasFormError: false,
      formErrorMessage: ''
    };
  },
  computed: {
    passwordFieldType() {
      return this.isPasswordVisible ? 'text' : 'password';
    },
    passwordFieldIcon() {
      return this.isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off';
    }
  },
  methods: {
    ...mapActions({
      signIn: types.actions.SIGN_IN
    }),
    changePasswordVisibility() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },
    setFieldsRules() {
      this.loginRules = LOGIN_RULES;
      this.passwordRules = PASSWORD_RULES;
    },
    async validateForm() {
      this.hasFormError = false;
      this.setFieldsRules();

      await this.$nextTick();

      return this.$refs.form.validate();
    },
    async onSignIn() {
      const isFormValid = await this.validateForm();

      if (!isFormValid) {
        return;
      }

      this.isLoading = true;

      const { error, shouldUseTwoFactorAuthentication } = await this.signIn({
        username: this.username,
        password: this.password
      });

      this.isLoading = false;

      if (shouldUseTwoFactorAuthentication) {
        this.$router.push({
          name: 'TwoFactorAuthentication',
          params: { username: this.username, password: this.password }
        });

        return;
      }

      if (error) {
        this.hasFormError = true;

        this.formErrorMessage =
          error === NETWORK_ERROR
            ? FORM_ERRORS_MESSAGES.NETWORK_ERROR
            : FORM_ERRORS_MESSAGES.CREDENTIALS_ERROR;

        return;
      }

      this.$router.push({ name: 'Dashboard' });
    }
  }
};
</script>

<style lang="scss" scoped>
.section {
  width: 400px;
  height: 373px;
  padding: 40px;
  background-color: #ffffff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);

  &__title {
    margin-bottom: 30px;
    font-size: 21px;
    line-height: 25px;
    color: #324752;
  }
}

.login-form {
  margin: 0 auto;
  max-width: 320px;
}
</style>
